<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Ingreso de producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Products -->
        <div>
          <label class="text-sm opacity-75">Producto</label>
          <v-select :options="products" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.product" class="mb-4 md:mb-0" name="product" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('product')">{{ errors.first('product') }}</span>
        </div>

        <!-- Warehouse -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Almacén</label>
          <v-select value="value" label="name" :options="warehouses" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.warehouse" class="mb-4 md:mb-0" name="warehouse" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
        </div>

        <!--Quantity-->
        <vs-input-number min="1" class="mt-5 mb-5" label="Cantidad:" v-model.number="input.quantity" name="quantity"
                         v-validate="'required|min_value:1'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>

        <!-- Motive -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Motivo</label>
          <v-select label="value" :options="motives" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="input.motive" class="mb-4 md:mb-0" name="motive" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('motive')">{{ errors.first('motive') }}</span>
        </div>

        <!-- Observation -->
        <vs-textarea @blur="input.observation= trimString(input.observation)" label="Observación"
                     v-model="input.observation" class="mt-5 w-full"
                     name="observation"/>
        <span class="text-danger text-sm"
              v-show="errors.has('observation')">{{ errors.first('observation') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      input: {
        product: null,
        warehouse: null,
        quantity: 0,
        motive: null
      },

      motives: [
        {value: 'Compra de mercadería'},
        {value: 'Reajuste de almácen'},
        {value: 'Devolución de productos'}
      ],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    async isSidebarActive(val) {
      if (!val) return
      this.progress = true
      this.initValues()
      await this.$validator.reset()
      this.progress = false
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          let input = {
            products: [
              {
                id: this.input.product.id,
                name: this.input.product.name,
                sku: this.input.product.sku,
                quantity: this.input.quantity,
                stocks: this.input.product.stocks ? this.input.product.stocks : []
              }
            ],
            warehouse: {
              id: this.input.warehouse.id,
              name: this.input.warehouse.name
            },
            motive: this.input.motive,
            observation: this.input.observation || '',
            origin: 'Web'
          }
          // New input
          db.collection('inputs').add({
            ...input,
            uid: firebase.auth().currentUser.uid,
            userName: firebase.auth().currentUser.displayName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then((docRef) => {
            let obj = {
              id: docRef.id,
              ...input,
              motive: input.motive.value,
              uid: firebase.auth().currentUser.uid,
              userName: firebase.auth().currentUser.displayName,
              createdAt: new Date()
            }
            this.$store.dispatch('inputs/addInput', {input: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Ingreso',
              text: 'Ingreso registrado correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    viewStock(p) {
      let text = ''
      p.stocks.forEach((s) => {
        text += `${s.warehouse.name}: ${s.stock} \n`
      })
      this.colorAlert = 'primary'
      this.$vs.dialog({
        color: this.colorAlert,
        title: `Stock de ${p.sku}`,
        text: text
      })
    },
    initValues() {
      this.input = {
        quantity: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
