<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <new :products="products" :warehouses="warehouses" :isSidebarActive="newSidebar"
         @closeSidebar="toggleNewSidebar" :data="sidebarData"/>

    <detail :products="products" :warehouses="warehouses" :isSidebarActive="detailSidebar"
            @closeSidebar="toggleDetailSideBar" :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="inputs">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="filterData">
                            <span class="flex items-center">
                              <feather-icon icon="FilterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Filtrar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new -->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>
        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ inputs.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : inputs.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="sku">Fecha</vs-th>
        <vs-th sort-key="sku">Hora</vs-th>
        <vs-th sort-key="warehouse">Almacén</vs-th>
        <vs-th sort-key="motive">Motivo</vs-th>
        <vs-th sort-key="origin">Producto</vs-th>
        <vs-th sort-key="origin">Cantidad</vs-th>
        <vs-th>Detalle</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | time(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.warehouse.name }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.motive }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.products[0].name }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.products[0].quantity }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon class="ml-2" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="viewDetail(tr)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Detail from "./Detail";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
const _ = require('lodash')

export default {
  name: 'ListInputs',
  components: {
    Detail,
    New
  },
  data() {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      inputsPre: [],
      initProgress: false,

      // Data Sidebar
      newSidebar: false,
      detailSidebar: false,
      sidebarData: {},

      products: [],
      warehouses: [],

      //Export
      cellAutoWidth: true,
      selectedFormat: 'xlsx'
    }
  },
  computed: {
    inputs: {
      get() {
        return this.$store.state.inputs.inputs
      }
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.inputs.length
    }
  },
  async created() {
    try {
      this.initProgress = true
      await this.loadProducts()
      await this.loadInputs()
      await this.loadWarehouses()
      this.initProgress = false
      this.$store.dispatch('inputs/fetchInputs', {inputs: this.inputsPre})
    } catch (e) {
      this.initProgress = false
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.newSidebar = true
    },
    viewDetail(data) {
      if (data) {
        this.sidebarData = data
        this.toggleDetailSideBar(true)
      }
    },
    toggleNewSidebar(val = false) {
      this.newSidebar = val
    },
    toggleDetailSideBar(val = false) {
      this.detailSidebar = val
    },
    loadInputs() {
      return new Promise((resolve, rejected) => {
        db.collection("inputs").orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let input = {
              id: doc.id,
              ...doc.data(),
              motive: doc.data().motive.value ? doc.data().motive.value : doc.data().motive,
              createdAt: doc.data().createdAt.toDate()
            }
            this.inputsPre.push(input)
          })
          resolve(true)
        }).catch((e) => {
          rejected(e)
        })
      })
    },
    loadWarehouses() {
      return new Promise((resolve, rejected) => {
        db.collection("warehouses").orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let warehouse = {
              id: doc.id,
              ...doc.data()
            }
            this.warehouses.push(warehouse)
          })
          resolve(true)
        }).catch((e) => {
          rejected(e)
        })
      })
    },
    loadProducts() {
      this.products = []
      return new Promise((resolve, rejected) => {
        console.log(this.$store.state.products.products.length)
        if (this.$store.state.products.products.length === 0) {
          db.collection("products").orderBy('createdAt', 'desc').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = {
                id: doc.id,
                name: doc.data().name,
                brand: doc.data().brand,
                category: doc.data().category,
                sku: doc.data().sku,
                priceDiscount: doc.data().priceDiscount,
                label: doc.data().name + '-' + doc.data().sku,
                stock: doc.data().stock,
                stocks: doc.data().stocks
              }
              this.products.push(product)
            })
            resolve(true)
          }).catch((e) => {
            rejected(e)
          })
        } else {
          this.$store.state.products.products.forEach((p) => {
            let product = {
              id: p.id,
              name: p.name,
              brand: p.brand,
              category: p.category,
              sku: p.sku,
              priceDiscount: p.priceDiscount,
              label: p.name + '-' + p.sku,
              stock: p.stock,
              stocks: p.stocks
            }
            this.products.push(product)
          })
          resolve(true)
        }
      })
    },
    filterData() {
      this.filter = !this.filter
    },
    exportToExcel() {
      this.initProgress = true
      import('@/vendor/Export2Excel').then(async (excel) => {
        const list = []
        const inputs = _.cloneDeep(this.inputsPre)
        for await (const i of inputs) {
          let input = {
            date: i.createdAt.toString(),
            warehouse: i.warehouse.name,
            motive: i.motive,
            product: i.products[0].name,
            sku: i.products[0].sku,
            quantity: i.products[0].quantity
          }
          list.push(input)
        }
        const headerVal = ['date', 'warehouse', 'motive', 'product', 'sku', 'quantity']
        const headerTitle = ['Fecha', 'Almácen', 'Motivo', 'Producto', 'SKU', 'Cantidad']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Inputs',
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.initProgress = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
